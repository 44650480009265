<template>
  <el-container class="layout">
    <el-header>
      <img src="@/assets/imgs/logo.png" />
      <div class="name">
        <h1>{{platformName}}</h1>
        <p>Intelligent Tool Management System of Wuling Assembly Workshop</p>
      </div>
      <div class="operate-box">
        <div class="message" @click="handleMessage"></div>
        <el-dropdown>
          <span class="el-dropdown-link">
            <img class="avatar" src="@/assets/imgs/user-avatar.png" />
            {{username}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu class="operate-menu">
              <el-dropdown-item @click="handlePersonalCen"><img class="icon" src="@/assets/imgs/personal-center.png" />个人中心</el-dropdown-item>
              <el-dropdown-item @click="handleLogout"><img class="icon" src="@/assets/imgs/logout.png" />退出</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>  
      </div>
    </el-header>
    <el-container class="body">
      <el-aside>
        <el-menu 
          @select="handleSelect"
          background-color="transparent"
          text-color="#B4B4C5"
          :default-active="activeIndex"
          :default-openeds="['assets', 'check', 'sys']">
          <el-menu-item index="home" v-if="menuAuth.indexOf('home') > -1"><img src="@/assets/imgs/menu-icon-home.png" />首页</el-menu-item>
          <el-submenu index="assets" v-if="menuAuth.indexOf('assetsList') > -1 || menuAuth.indexOf('assetsCheck') > -1 || menuAuth.indexOf('assetsLife') > -1">
            <template #title>资产管理</template>
            <el-menu-item index="assetsList" v-if="menuAuth.indexOf('assetsList') > -1"><img class="icon" src="@/assets/imgs/menu-icon-assets-list.png" />资产清单</el-menu-item>
            <el-menu-item index="assetsCheck" v-if="menuAuth.indexOf('assetsCheck') > -1"><img class="icon" src="@/assets/imgs/menu-icon-assets-check.png" />资产盘点</el-menu-item>
            <el-menu-item index="assetsLife" v-if="menuAuth.indexOf('assetsLife') > -1"><img class="icon" src="@/assets/imgs/menu-icon-assets-life.png" />工具寿命管理</el-menu-item>
            <el-menu-item index="nonkeyTool" v-if="menuAuth.indexOf('nonkeyTool') > -1"><img class="icon" src="@/assets/imgs/menu-icon-nonkey-tool.png" />非关键工具管理</el-menu-item>
          </el-submenu>
          <el-submenu index="check" v-if="menuAuth.indexOf('checkRecord') > -1">
            <template #title>点检管理</template>
            <el-menu-item index="checkRecord" v-if="menuAuth.indexOf('checkRecord') > -1"><img class="icon" src="@/assets/imgs/menu-icon-check-record.png" />关键工具点检记录</el-menu-item>
            <el-menu-item index="nonkeyCheckRecord" v-if="menuAuth.indexOf('nonkeyCheckRecord') > -1"><img class="icon" src="@/assets/imgs/menu-icon-nonkey-check-record.png" />非关键工具点检记录</el-menu-item>
          </el-submenu>
          <el-submenu index="sys" v-if="menuAuth.indexOf('sysUser') > -1 || menuAuth.indexOf('sysConfig') > -1">
            <template #title>系统管理</template>
            <el-menu-item index="sysUser" v-if="menuAuth.indexOf('sysUser') > -1"><img class="icon" src="@/assets/imgs/menu-icon-sys-user.png" />用户管理</el-menu-item>
            <el-menu-item index="sysConfig" v-if="menuAuth.indexOf('sysConfig') > -1"><img class="icon" src="@/assets/imgs/menu-icon-sys-config.png" />系统配置</el-menu-item>
            <el-menu-item index="notice" v-if="menuAuth.indexOf('notice') > -1"><img class="icon" src="@/assets/imgs/menu-icon-notice.png" />通知公告</el-menu-item>
            <el-menu-item index="integral" v-if="menuAuth.indexOf('integral') > -1"><img class="icon" src="@/assets/imgs/menu-icon-integral.png" />积分管理</el-menu-item>
            <el-menu-item index="employeeFeedback" v-if="menuAuth.indexOf('employeeFeedback') > -1"><img class="icon" src="@/assets/imgs/menu-icon-employee-feedback.png" />员工反馈</el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view />
      </el-main>
    </el-container>

    <!-- 消息弹框 -->
    <el-dialog v-model="sysMsgDialogVisible" width="800px" @close="sysMsgDialogVisible = false">
      <template #title>
        <h1>系统消息</h1>
        <p>System message</p>
      </template>
      <template v-if="sysMsgList.length > 0">
        <ul class="sys-msg-list">
          <li v-for="item in sysMsgList" :key="item.id" :class="{'un-read': item.status == 'UN_READ'}">
            <div class="msg-main">
              <p class="label">{{item.content}}</p>
              <p class="time">{{item.createTime}}</p>
            </div>
            <el-button @click="signReaded(item)" v-if="item.status == 'UN_READ'">我知道了</el-button>
          </li>
        </ul>
        <div class="pagination-box">
          <el-pagination
            @current-change="handleCurrentPage"
            :current-page="sysMsgPagination.currentPage"
            :page-size="sysMsgPagination.pageSize"
            layout="prev, pager, next"
            :total="sysMsgPagination.total">
          </el-pagination>
        </div>
      </template>
      <div v-else class="no-data">暂无数据</div>
    </el-dialog>
  </el-container>
</template>

<script>
import {parseJwt} from '@/utils/common'
import { common } from '@/api'

export default {
  name: 'Layout',
  data(){
    return {
      platformName: window.PlatformName,
      activeIndex: this.$route.name, // 当前激活的菜单，默认值根据路由名称决定
      sysMsgDialogVisible: false, // 系统消息弹框visible
      sysMsgList: [], // 系统消息列表
      sysMsgPagination: { // 系统消息分页信息
        currentPage: 1,
        pageSize: 5,
        total: 0
      },
    }
  },
  computed: {
    username() {
      if(localStorage.getItem('wlzcToken')) {
        return parseJwt(localStorage.getItem('wlzcToken').split(' ')[1]).name
      }
      return ''
    },
    menuAuth() {
      let uma = localStorage.getItem('userMenuAuth')
      if(uma) {
        let umaObj = JSON.parse(uma) || []
        return umaObj.map(item => {
          return item.code
        })
      }
      return []
    }
  },
  methods: {
    // 退出
    async handleLogout() {
      localStorage.setItem('wlzcToken', '')
      localStorage.setItem('userMenuAuth', '')
      this.$router.push({name: 'login'})
    },
    // 选中菜单，进入对应页面
    handleSelect(index) {
      this.$router.push({name: index})
    },
    // 进入个人中心
    handlePersonalCen() {
      this.$router.push({name: 'personalCenter'})
    },
    // 点击系统消息
    handleMessage() {
      this.loadSysMsg()
      this.sysMsgDialogVisible = true
    },
    // 查询系统消息
    async loadSysMsg() {
      let params = {
        pageNum: this.sysMsgPagination.currentPage,
        pageSize: this.sysMsgPagination.pageSize
      }
      const {code, msg, data, total} = await common.getSysMsg(params)
      if(code === 0) {
        this.sysMsgList = data || []
        this.sysMsgPagination.total = total
      } else {
        this.$message.error(msg)
      }
    },
    // 系统消息-改变当前页
    handleCurrentPage(currentPageNo) {
      this.sysMsgPagination.currentPage = currentPageNo
      this.loadSysMsg()
    },
    // 标记信息为已读
    async signReaded({id}) {
      const {code, msg} = await common.readSysMsg({id})
      if(code === 0) {
        this.sysMsgPagination.currentPage = 1
        this.loadSysMsg()
      } else {
        this.$message.error(msg)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.layout.el-container {
  height: 100vh;
  background: #262638;
  overflow: auto;
  .el-header {
    height: 4.5vw !important;
    max-height: 85px;
    background: linear-gradient(180deg, #34344C 0%, #1B1B27 100%);
    box-shadow: 0px 11px 14px 0px rgba(9, 9, 13, 0.5);
    padding: 0 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    & > img {
      // width: 68px;
      // height: 36px;
      height: 42px;
    }
    .name {
      margin-left: 20px;
      flex-grow: 1;
      h1 {
        font-size: 16px;
      }
      p {
        font-size: 12px;
        color: #B4B4C5;
      }
    }
    .operate-box {
      display: flex;
      align-items: center;
      .message {
        width: 24px;
        height: 24px;
        margin-right: 20px;
        background: url(~@/assets/imgs/msg-icon.png) center center / 100% no-repeat;
        position: relative;
        cursor: pointer;
        &::after{
          content: "";
          display: block;
          height: 8px;
          width: 8px;
          border-radius: 4px;
          position: absolute;
          right: 0;
          top: 0;
          background: #FA3535;
        }
      }
      .el-dropdown {
        .el-dropdown-link {
          display: flex;
          align-items: center;
          font-size: 16px;
          @media screen and (min-width: 1500px) {
            font-size: 18px;
          }
          color: #fff;
          font-weight: bold;
          .avatar {
            width: 32px;
            height: 32px;
            margin-right: 12px;
          }
        }
      }
    }
  }
  .body.el-container {
    padding-top: 12px;
    .el-aside {
      width: 15vw !important;
      max-width: 300px;
      min-width: 200px;
      background: linear-gradient(216deg, #303047 0%, #1B1B27 100%);
      box-shadow: 0px 11px 14px 0px rgba(9, 9, 13, 0.65);
      border-radius: 0px 16px 0px 0px;
      padding: 0.5vw;
      @media screen and (min-width: 1500px){
        padding-left: 1.2vw;
      }
      padding-top: 60px;
      .el-menu {
        border-right: none;
        .el-menu-item {
          width: 10vw;
          min-width: 150px;
          background-color: transparent !important;
          padding-left: 20px !important;
          margin-left: 20px;
          padding-right: 0;
          margin-right: 60px;
          height: 50px;
          line-height: 50px;
          & > img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            position: relative;
            bottom: 2px;
          }
          &.is-active {
            color: #FFFFFF;
            font-weight: bold;
            background: linear-gradient(135deg, #8181AF 0%, #5F5F90 100%);
            box-shadow: 0px 9px 12px -2px rgba(9, 9, 13, 0.65);
            border-radius: 8px;
          }
        }
        .el-submenu {
          ::v-deep .el-submenu__title {
            height: 50px;
            background-color: transparent !important;
            i {
              display: none;
            }
          }
        }
      }
    }
    .el-main {
      padding: 2px 1.5vw .8vw 18px;
      background: url(~@/assets/imgs/main-bg.png) center center /cover no-repeat;
    }
  }
}
.operate-menu {
  .el-dropdown-menu__item {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #fff;
    &:focus, &:not(.is-disabled):hover {
      background: transparent;
    }
    .icon {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }
}

.sys-msg-list {
  list-style: none;
  & > li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #b4b4c59c;
    border-bottom: 1px solid rgba(151, 151, 151, 0.3);
    padding: 12px 0;
    &:first-of-type{
      padding-top: 0;
    }
    &.un-read {
      color: #fff;
      .msg-main {
        &::before {
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 4px;
          background: #FA3535;
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
        }
      }
      
    }
    .msg-main {
      position: relative;
      padding-left: 14px;
      & > .label {
        font-size: 14px;
      }
      & > .time {
        font-size: 12px;
        margin-top: 6px;
      }
    }
    .el-button {
      margin-left: 10px;
    }
  }
}
</style>